

export const Services = [
    {
        id: "01",
        name: "Taglio",
        description: "",
        price: 20,
        duration: 30,
        barbers: ["Andrea", "Mirko"],
        image: "https://i.ibb.co/kcS07Wr/placeholder-scalp-squad.png"
    },
    {
        id: "02",
        name: "Taglio Lungo",
        description: "",
        price: 35,
        duration: 60,
        barbers: ["Andrea"],
        image: "https://i.ibb.co/kcS07Wr/placeholder-scalp-squad.png"
    },
    {
        id: "03",
        name: "Barba",
        description: "",
        price: 18,
        duration: 30,
        barbers: ["Andrea", "Mirko"],
        image: "https://i.ibb.co/kcS07Wr/placeholder-scalp-squad.png"
    },
    {
        id: "04",
        name: "Taglio + Barba",
        description: "",
        price: 38,
        duration: 60,
        barbers: ["Andrea", "Mirko"],
        image: "https://i.ibb.co/kcS07Wr/placeholder-scalp-squad.png"
    },
    {
        id: "05",
        name: "Rasatura + Barba",
        description: "",
        price: 25,
        duration: 30,
        barbers: ["Andrea", "Mirko"],
        image: "https://i.ibb.co/kcS07Wr/placeholder-scalp-squad.png"
    },
    {
        id: "06",
        name: "Taglio Lungo + Barba",
        description: "",
        price: 50,
        duration: 90,
        barbers: ["Andrea"],
        image: "https://i.ibb.co/kcS07Wr/placeholder-scalp-squad.png"
    },
    {
        id: "07",
        name: "Taglio + Barba + Trattamento specifico",
        description: "",
        price: 55,
        duration: 90,
        barbers: ["Andrea", "Mirko"],
        image: "https://i.ibb.co/kcS07Wr/placeholder-scalp-squad.png"
    },
    {
        id: "08",
        name: "Barba con panno caldo",
        description: "",
        price: 23,
        duration: 30,
        barbers: ["Andrea", "Mirko"],
        image: "https://i.ibb.co/kcS07Wr/placeholder-scalp-squad.png"
    },
]